import { ref, getCurrentInstance } from 'vue'
import useJwt from '@/auth/jwt/useJwt'

export default function useChatIde() {
  const root = getCurrentInstance().proxy.$root
  const { currentGateway } = root.$store.state.dataFlow
  const messages = ref([])
  const jsonMessages = ref([{
    key: 'message',
    value: '',
  }])
  const typedMessage = ref('')
  const messageXSession = ref(null)

  messages.value = JSON.parse(JSON.stringify(root.$store.state.dataFlow.interactiveActions[currentGateway]?.chat ?? []))

  const sendMessage = (roomId, mode, node, jsonInput) => {
    root.$store.dispatch('dataFlow/setBotTyping', true)
    const message = typedMessage.value
    const messageBody = {
      from: 'human',
      message,
    }
    messages.value.push(messageBody)
    root.$store.dispatch('dataFlow/setInteractiveActions', {
      type: 'chat',
      data: messageBody,
    })
    const params = jsonInput ?? { message }

    const conditionApi = root.$store.state.dataFlow.currentGateway
    useJwt.getChatNodeApi(conditionApi,
      params,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'x-debug': roomId,
          ...(messageXSession.value ? {
            'x-session': messageXSession.value,
          } : []),
          ...(mode && node ? {
            'x-mode': mode,
            'x-node': node,
          } : []),
        },
      }).then(response => {
      const responseMessage = {
        from: 'chatbot',
        message: response.data.message,
      }
      messages.value.push(responseMessage)
      root.$store.dispatch('dataFlow/setInteractiveActions', {
        type: 'chat',
        data: responseMessage,
      })
      messageXSession.value = response.data['x-session']
    }).finally(() => {
      root.$store.dispatch('dataFlow/setBotTyping', false)
    })
    typedMessage.value = ''
    // useJwt.getBotResponse({
    //   chat_history: messages.value.map(item => {
    //     if (item.from === 'bot') {
    //       return {
    //         chatbot: item.message,
    //       }
    //     }
    //     return {
    //       human: item.message,
    //     }
    //   }),
    // }).then(response => {
    //   messages.value.push({
    //     from: 'chatbot',
    //     message: response.data.data.response,
    //   })
    // }).catch(error => {
    //   root.showErrorMessage(error)
    // }).finally(() => {
    //   isBotTyping.value = false
    // })
  }

  const clearChat = () => {
    messages.value = []
    messageXSession.value = null
  }

  return {
    typedMessage,
    sendMessage,
    clearChat,
    messages,
    jsonMessages,
  }
}
