<template>
  <b-modal
    id="email-node-modal"
    :title="`${form.uuid ? 'Update' : 'Add'} Email Node Properties`"
    size="xl"
    :ok-title="form.uuid ? 'Update' : 'Add'"
    static
    no-close-on-backdrop
    @ok="handleOk"
    @shown="populateForm"
    @hidden="resetForm"
  >
    <validation-observer ref="emailNodeForm">
      <b-overlay :show="isProcessing">
        <b-row>
          <b-col md="6">
            <!-- Description -->
            <validation-provider
              v-slot="{ errors }"
              name="description"
              rules="required"
            >
              <b-form-group label="Description">
                <b-form-textarea
                  v-model="form.description"
                  placeholder="Explain what this node does"
                  :rows="form.uuid ? 8 : 4"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <!-- Name -->
            <validation-provider
              v-if="form.uuid"
              v-slot="{ errors }"
              name="name"
              rules="required"
            >
              <b-form-group label="Name">
                <b-form-input
                  v-model="form.name"
                  placeholder="Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <b-row>
              <b-col md="9">
                <!-- From Email -->
                <validation-provider
                  v-slot="{ errors }"
                  name="'from email'"
                  rules="required|email"
                >
                  <b-form-group label="From Email">
                    <b-form-input
                      v-model="form.email"
                      placeholder="From Email"
                      type="email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="3">
                <!-- TLS / STARTTLS -->
                <validation-provider
                  v-slot="{ errors }"
                  name="tls"
                  rules="required"
                >
                  <b-form-group label="TLS / STARTTLS">
                    <b-form-checkbox
                      v-model="form.tls"
                      switch
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="8">
                <!-- Host -->
                <validation-provider
                  v-slot="{ errors }"
                  name="host"
                  rules="required"
                >
                  <b-form-group label="Host">
                    <b-form-input
                      v-model="form.host"
                      placeholder="Host"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="4">
                <!-- Port -->
                <validation-provider
                  v-slot="{ errors }"
                  name="port"
                  rules="required|integer"
                >
                  <b-form-group label="Port">
                    <b-form-input
                      v-model="form.port"
                      placeholder="Port"
                      type="number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6">
                <!-- Username -->
                <validation-provider
                  v-slot="{ errors }"
                  name="username"
                  rules="required"
                >
                  <b-form-group label="Username">
                    <b-form-input
                      v-model="form.username"
                      placeholder="Username"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="6">
                <!-- Password -->
                <validation-provider
                  v-slot="{ errors }"
                  name="password"
                  rules="required"
                >
                  <b-form-group label="Password">
                    <b-form-input
                      v-model="form.password"
                      placeholder="Password"
                      type="password"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <!-- Subject -->
            <validation-provider
              v-slot="{ errors }"
              name="subject"
              rules="required"
            >
              <b-form-group label="Subject">
                <b-form-input
                  v-model="form.params.subject"
                  placeholder="Subject"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- To -->
            <validation-provider
              v-slot="{ errors }"
              name="'to'"
              rules="required|email"
            >
              <b-form-group label="To">
                <v-select
                  v-model="form.params.to"
                  placeholder="To"
                  multiple
                  taggable
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <!-- CC -->
            <validation-provider
              v-slot="{ errors }"
              name="'cc'"
              rules="email"
            >
              <b-form-group label="CC">
                <v-select
                  v-model="form.params.cc"
                  placeholder="CC"
                  multiple
                  taggable
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- BCC -->
            <validation-provider
              v-slot="{ errors }"
              name="'bcc'"
              rules="email"
            >
              <b-form-group label="BCC">
                <v-select
                  v-model="form.params.bcc"
                  placeholder="BCC"
                  multiple
                  taggable
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- Message -->
        <validation-provider
          v-slot="{ errors }"
          name="message"
          rules="required"
        >
          <b-form-group label="Message">
            <b-form-textarea
              v-model="form.params.message"
              placeholder="Write your message here ..."
              rows="10"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-overlay>
    </validation-observer>
  </b-modal>
</template>

<script setup>
import {
  BRow, BCol, BModal, BFormGroup, BFormInput, BFormTextarea, BFormCheckbox, BOverlay,
  // VBTooltip,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { getCurrentInstance, ref } from 'vue'
import useJwt from '@/auth/jwt/useJwt'
import { NODE_TYPES } from '../nodeConstants'

// const vBTooltip = VBTooltip
const props = defineProps({
  properties: {
    type: Object,
    default: () => {},
  },
  node: {
    type: Object,
    required: true,
  },
})

const root = getCurrentInstance().proxy
const emit = defineEmits('node-properties', 'refetch')
const isProcessing = ref(false)
const emailNodeForm = ref()
const form = ref({
  params: {},
  tls: false,
})

// Methods
const populateForm = () => {
  if (Object.keys(props.properties).length) {
    const { params, ...properties } = props.properties
    form.value = properties
    form.value.params = {}
    console.log(params)
    params.forEach(param => {
      if (param.name === 'to') {
        if (Array.isArray(param.value)) {
          // If param.value is an array, assign it directly
          form.value.params.to = param.value
        } else {
          // If param.value is not an array, handle it as a string and split it
          const emails = param.value.replace(/[[\]']/g, '') // Clean up the string
          form.value.params.to = emails.split(',') // Split into an array
        }
      } else if (param.name === 'cc') {
        if (Array.isArray(param.value)) {
          // If param.value is an array, assign it directly
          form.value.params.cc = param.value
        } else {
          // If param.value is not an array, handle it as a string and split it
          const ccEmails = param.value.replace(/[[\]']/g, '') // Clean up the string
          form.value.params.cc = ccEmails.split(',') // Split into an array
        }
      } else if (param.name === 'bcc') {
        if (Array.isArray(param.value)) {
          // If param.value is an array, assign it directly
          form.value.params.bcc = param.value
        } else {
          // If param.value is not an array, handle it as a string and split it
          const bccEmails = param.value.replace(/[[\]']/g, '') // Clean up the string
          form.value.params.bcc = bccEmails.split(',') // Split into an array
        }
      } else {
        form.value.params[param.name] = param.value
      }
    })
    console.log(form.value)
  }
  if (props.node.uuid) form.value.name = props.node.name
}

const resetForm = () => {
  form.value = {
    params: {},
    tls: false,
  }
  emailNodeForm.value.reset()
}

const handleOk = bvModal => {
  bvModal.preventDefault()
  emailNodeForm.value.validate().then(success => {
    if (success) {
      isProcessing.value = true
      const { data, params, ...formData } = form.value
      if (data) formData.data = data

      formData.params = []
      Object.entries(params).forEach(([key, value]) => {
        formData.params.push({
          name: key,
          value,
        })
      })

      emit('node-properties', formData)
      const payload = {
        ...props.node,
        ...formData,
        node_type: NODE_TYPES.email,
      }

      if (form.value.uuid) {
        useJwt.updateNode(payload).then(response => {
          root.showSuccessMessage(response)
        }).finally(() => {
          root.$bvModal.hide('email-node-modal')
          isProcessing.value = false
        })
      } else {
        root.$bvModal.hide('email-node-modal')
        isProcessing.value = false
      }
    }
  })
}
</script>
