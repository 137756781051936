import { NODE_TYPES } from './nodeConstants'

const contextMenu = (uuid, nodeType) => {
  let html = '<div class="more-options shadow shadow-md d-none">'

  if (uuid && nodeType !== 'gateway') {
    html += `
      ${nodeType !== 'workflow' && nodeType !== 'conditional'
    ? '<p class="small my-0 cursor-pointer single-run-btn">Single Run</p>'
    : ''}

      <p class="small my-0 cursor-pointer family-run-btn">Family Run</p>
      <hr class="my-0">
    `
  }

  html += `
      <p class="small my-0 cursor-pointer text-danger df-delete">Delete</p>
    </div>
  `
  return html
}

// Templates
export const defaultTemplate = (label = '', uuid = null) => `
  <div class="box">
    <small class="node-label text-white">${label}</small>
    <input
      df-template
      draggable
      ${uuid ? 'disabled' : ''}
      placeholder="${label}"
      onkeypress="${label.toLowerCase() === 'gateway' ? 'return event.charCode != 32' : ''}"
    />
    ${contextMenu(uuid, label.toLowerCase())}
  </div>
`

const workflowTemplate = (label = 'Workflow Node', uuid = null, sharedNode = null) => `
  <div class="box">
    <div class="d-flex">
      <small class="node-label text-white">${sharedNode ? 'Shared Workflow' : label}</small>
      ${sharedNode ? `<i class="shared-icon fas fa-link text-white" title="Shared with '${sharedNode.name}'"></i>` : ''}
    </div>
    <input
      df-template
      draggable
      placeholder="${label}"
      ${uuid ? 'disabled' : ''}
    />
    <div class="output-labels">
      <p class="handler">Handler</p>
      <p class="next-handler">Executes Next</p>
    </div>
  </div>
  ${contextMenu(uuid, 'workflow')}
`
const decisionTemplate = (label, uuid = null) => `
  <div class="box">
    <small class="node-label text-white">${label}</small>
    <input
      df-template
      draggable
      placeholder="${label}"
      ${uuid ? 'disabled' : ''}
    />
    <div class="label-true w-100">
      <label>True</abel>
    </div>
    <div class="label-false w-100">
      <label>False</label>
    </div>
    ${contextMenu(uuid, 'conditional')}
  </div>
`

export const getTemplate = (nodeType, label, uuid, sharedNode = null) => {
  switch (nodeType) {
    case NODE_TYPES.workflow:
      return workflowTemplate('Workflow Node', uuid, sharedNode)
    case NODE_TYPES.conditional:
    case NODE_TYPES.gptconditional:
      return decisionTemplate(label, uuid)
    default:
      return defaultTemplate(label, uuid)
  }
}

const getNode = (posX, posY, node = null) => {
  switch (node) {
    case 'GATEWAY':
      return [
        'GATEWAY',
        0, 1, posX, posY, 'node gateway',
        { template: '' },
        defaultTemplate('Gateway'),
      ]
    case 'GPT_CONDITIONAL_NODE':
    case 'CONDITIONAL_NODE':
      return [
        'CONDITIONAL_NODE',
        1, // Number of inputs
        2, // Number of outputs
        posX, // X position
        posY, // Y position
        node === 'CONDITIONAL_NODE' ? 'node conditional-node' : 'node gpt-conditional-node', // Class name
        {
          template: '',
        }, // Data
        decisionTemplate(node === 'CONDITIONAL_NODE' ? 'Conditional Node' : 'GPT Conditional Node'), // HTML content
      ]
    case 'DB_NODE':
      return [
        'DB_NODE', 1, 0, posX, posY,
        'node db-node',
        { template: '' },
        defaultTemplate('DB Node'),
      ]
    case 'GPT_NODE':
      return [
        'GPT_NODE', 1, 1, posX, posY,
        'node gpt-node',
        { template: '' },
        defaultTemplate('GPT Node'),
      ]
    case 'HTTP_NODE':
      return [
        'HTTP_NODE', 1, 0, posX, posY,
        'node http-node',
        { template: '' },
        defaultTemplate('HTTP Node'),
      ]
    case 'FILE_NODE':
      return [
        'FILE_NODE', 1, 0, posX, posY,
        'node file-node',
        { template: '' },
        defaultTemplate('File Node'),
      ]
    case 'CHAT_OLLAMA_NODE':
      return [
        'CHAT_OLLAMA_NODE', 1, 0, posX, posY,
        'node chat-ollama-node',
        { template: '' },
        defaultTemplate('Chat Ollama Node'),
      ]
    case 'WORKFLOW_NODE':
      return [
        'WORKFLOW_NODE', 1, 2, posX, posY,
        'node workflow-node',
        { template: '' },
        workflowTemplate(),
      ]
    case 'BRANCH_NODE':
      return [
        'BRANCH_NODE', 1, 1, posX, posY,
        'node branch-node',
        { template: '' },
        defaultTemplate('Branch Node'),
      ]
    case 'SHARED_WORKFLOW_NODE':
      return [
        'SHARED_WORKFLOW_NODE', 1, 0, posX, posY,
        'node shared-workflow-node shared',
        { template: '' },
        defaultTemplate('Shared Workflow'),
      ]
    case 'JOIN_NODE':
      return [
        'JOIN_NODE', 1, 0, posX, posY,
        'node join-node',
        { template: '' },
        defaultTemplate('Join Node'),
      ]
    case 'EMAIL_NODE':
      return [
        'EMAIL_NODE', 1, 0, posX, posY,
        'node email-node',
        { template: '' },
        defaultTemplate('Email Node'),
      ]
    case 'SCRAPER_NODE':
      return [
        'SCRAPER_NODE', 1, 0, posX, posY,
        'node scraper-node',
        { template: '' },
        defaultTemplate('Scraper Node'),
      ]
    default:
      return [
        'Process', 1, 1, posX, posY,
        'process',
        { template: '' },
        defaultTemplate,
      ]
  }
}

export default getNode
