<template>
  <b-modal
    id="json-input-modal"
    title="Message"
    size="lg"
    ok-title="Done"
    @ok="handleOk"
  >
    <validation-observer ref="jsonModeForm">
      <div
        v-for="message, index of jsonMessages"
        :key="index"
        class="border-bottom py-1"
      >
        <b-row class="align-items-baseline">
          <b-col md="5">
            <validation-provider
              v-slot="{ errors }"
              name="key"
              rules="required"
            >
              <b-form-input
                v-model="message.key"
                placeholder="Key"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              v-slot="{ errors }"
              name="value"
              rules="required"
            >
              <b-form-input
                v-model="message.value"
                placeholder="Value"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col
            v-if="message.key !== 'message'"
            md="1"
          >
            <feather-icon
              class="text-danger cursor-pointer"
              icon="Trash2Icon"
              @click="removeItem(index)"
            />
          </b-col>
        </b-row>
      </div>
    </validation-observer>

    <div>
      <b-button
        variant="link"
        size="sm"
        @click="jsonMessages.push({ key: '', value: '' })"
      >
        <feather-icon icon="PlusIcon" />
        Add Key/Value Pair
      </b-button>
    </div>
  </b-modal>
</template>

<script setup>
import {
  BModal, BRow, BCol, BFormInput, BButton,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { getCurrentInstance, ref } from 'vue'
import useChatIde from './useChatIde'

const { jsonMessages } = useChatIde()
const jsonModeForm = ref()
const root = getCurrentInstance().proxy

const emits = defineEmits('message')

// Methods
const removeItem = index => {
  jsonMessages.value.splice(index, 1)
}

const handleOk = bvModal => {
  bvModal.preventDefault()
  jsonModeForm.value.validate().then(success => {
    if (success) {
      root.$bvModal.hide('json-input-modal')
      const messages = {}
      jsonMessages.value.forEach(({ key, value }) => {
        messages[key] = value
      })
      emits('message', messages)
    }
  })
}
</script>
