import store from '@/store'
import { ref } from 'vue'

export default function useResponseExplorer() {
  const { currentGateway } = store.state.dataFlow
  const responseData = ref(store.state.dataFlow.interactiveActions[currentGateway]?.responseExplorer ?? [])

  const pushResponseMessage = message => {
    const input = message.data
    const { uuid, name, output } = message

    const responseExplorerData = {
      uuid,
      name,
      input,
      output,
    }
    responseData.value.push(responseExplorerData)
    store.dispatch('dataFlow/setInteractiveActions', {
      type: 'responseExplorer',
      data: responseExplorerData,
    })
  }

  return {
    responseData,
    pushResponseMessage,
  }
}
