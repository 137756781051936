<template>
  <div>
    <b-modal
      id="workflow-node-modal"
      :title="`${form.uuid ? 'Update' : 'Add'} Workflow Node Properties`"
      :ok-title="`${form.uuid ? 'Update' : 'Add'}`"
      size="lg"
      static
      no-close-on-backdrop
      @ok="handleOk"
      @shown="populateForm"
      @hidden="() => { form = {}; workflowNodeForm.reset() }"
    >
      <validation-observer ref="workflowNodeForm">
        <b-overlay :show="isProcessing">
          <b-row class="align-items-baseline">
            <!-- Name -->
            <b-col md="10">
              <validation-provider
                v-if="form.uuid"
                v-slot="{ errors }"
                name="name"
                rules="required"
              >
                <b-form-group label="Name">
                  <b-form-input
                    v-model="form.name"
                    placeholder="Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md="2"
              class="text-right pr-2"
            >
              <b-form-checkbox
                v-model="form.shareable"
                switch
              >
                <span class="cursor-pointer">Share</span>
              </b-form-checkbox>
            </b-col>
          </b-row>

          <!-- Description -->
          <validation-provider
            v-slot="{ errors }"
            name="description"
            rules="required"
          >
            <b-form-group label="Description">
              <b-form-textarea
                v-model="form.description"
                placeholder="Explain what this node does"
                rows="5"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Data -->
          <!-- <b-col md="8">
            <validation-provider
              v-slot="{ errors }"
              name="data"
            >
              <b-form-group>
                <template #label>
                  <span>Data</span>
                  <feather-icon
                    v-b-tooltip.hover="'While performing single run, enter the data to replicate passed down data from the parent node'"
                    icon="InfoIcon"
                    :style="'margin-left: 4px'"
                  />
                </template>
                <b-form-input
                  v-model="form.data"
                  placeholder="Data"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col> -->
        </b-overlay>
      </validation-observer>
    </b-modal>

    <confirm-modal
      modal="confirm-shareable-change-modal"
      title="Confirm Update"
      description="This workflow node might have been shared with other workflow nodes, turning off sharing feature might affect the others. Do you want to proceed?"
      ok-title="Confirm"
      :is-static="true"
      @confirm="updateNode"
    />
  </div>
</template>

<script setup>
import { ref, getCurrentInstance } from 'vue'
import {
  BRow, BCol, BOverlay, BFormTextarea, BFormGroup, BFormInput, BFormCheckbox, BModal,
  // VBTooltip,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ConfirmModal from '@/views/Modals/ConfirmModal.vue'
import useJwt from '@/auth/jwt/useJwt'
import { NODE_TYPES } from '../nodeConstants'

// const vBTooltip = VBTooltip
const props = defineProps({
  properties: {
    type: Object,
    default: () => {},
  },
  node: {
    type: Object,
    required: true,
  },
})

const root = getCurrentInstance().proxy
const emit = defineEmits('node-properties', 'refetch')

const isProcessing = ref(false)
const form = ref({})
const workflowNodeForm = ref()

// Methods
const populateForm = () => {
  if (Object.keys(props.properties).length) {
    form.value = JSON.parse(JSON.stringify(props.properties))
  }
  if (props.node.uuid) form.value.name = props.node.name
}

const updateNode = () => {
  isProcessing.value = true
  const { data, ...formData } = form.value
  if (data) formData.data = data
  emit('node-properties', formData)

  if (formData.uuid) {
    const params = {
      ...props.node,
      ...formData,
      shareable: formData.shareable ?? false,
      node_type: NODE_TYPES.workflow,
    }

    useJwt.updateNode(params).then(response => {
      root.showSuccessMessage(response)
      // emit('refetch')
      root.$bvModal.hide('workflow-node-modal')
    }).catch(error => {
      root.showErrorMessage(error)
    }).finally(() => {
      isProcessing.value = false
    })
  } else {
    isProcessing.value = false
    root.$bvModal.hide('workflow-node-modal')
  }
}

const handleOk = bvModal => {
  bvModal.preventDefault()
  workflowNodeForm.value.validate().then(success => {
    if (success) {
      if (props.properties.shareable && !form.value.shareable) {
        root.$bvModal.show('confirm-shareable-change-modal')
      } else updateNode()
    }
  })
}
</script>
