import { NODE_TYPES } from './nodeConstants'

const multipleInputAllowedNodes = [
  NODE_TYPES.conditional,
  NODE_TYPES.gptconditional,
  NODE_TYPES.join,
]

export default {
  getNodeClass: (hasParent, nodeType, hasShares) => {
    if (!hasParent) return 'gateway'
    if (hasShares) return 'shared-workflow-node shared'
    return nodeType.split('_').join('-').toLowerCase()
  },
  nodeHasOnlyDescription: nodeType => {
    switch (nodeType) {
      case 'gateway':
      case 'json-response-node':
      case 'branch-node':
      case 'join-node':
        return true
      default:
        return false
    }
  },
  hasOutputs(nodeType, side) {
    switch (nodeType) {
      case NODE_TYPES.workflow:
        return true
      case NODE_TYPES.gateway:
      case NODE_TYPES.conditional:
      case NODE_TYPES.gptconditional:
        return true
      case NODE_TYPES.branch:
      case NODE_TYPES.gpt:
        // only has one output: left
        return side === 'left'
      default:
        return false
    }
  },
  setModalComponent: nodeType => {
    const nodes = ['gateway', 'branch-node', 'join-node']

    if (nodes.includes(nodeType)) return 'NodeDescriptionModal'
    return `${nodeType.split('-').map(n => n[0].toUpperCase() + n.substr(1)).join('')}Modal`
  },
  multipleInputDisallowed: (connectionLength, inputNode, outputNode) => {
    if ((
      // only gpt node and workflow node can give input to join node
      inputNode === NODE_TYPES.join && outputNode !== NODE_TYPES.gpt && outputNode !== NODE_TYPES.workflow
    )
    // gpt node can give input to join node only
    || (outputNode === NODE_TYPES.gpt && inputNode !== NODE_TYPES.join)) return true

    // allow multiple inputs to conditional node and gpt-conditional node
    if (multipleInputAllowedNodes.includes(inputNode)) return false

    // disallow multiple inputs to a same node except for join node
    if (connectionLength > 1 && inputNode !== NODE_TYPES.join) return true
    return false
  },
  multipleOutputDisallowed: (connectionLength, outputNode) => connectionLength > 1 && outputNode !== NODE_TYPES.branch,
}
