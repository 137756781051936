<template>
  <b-modal
    id="gpt-node-modal"
    :title="`${form.uuid ? 'Update' : 'Add'} GPT Node Properties`"
    size="xl"
    :ok-title="form.uuid ? 'Update' : 'Add'"
    static
    no-close-on-backdrop
    @ok="handleOk"
    @shown="populateForm"
    @hidden="clearForm"
  >
    <validation-observer ref="gptNodeForm">
      <b-overlay :show="isProcessing">
        <b-row class="justify-content-between">
          <!-- Description -->
          <b-col md="6">
            <validation-provider
              v-slot="{ errors }"
              name="description"
              rules="required"
            >
              <b-form-group label="Description">
                <b-form-textarea
                  v-model="form.description"
                  placeholder="Explain what this node does"
                  :rows="form.uuid ? 8 : 4"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <!-- Name -->
            <validation-provider
              v-if="form.uuid"
              v-slot="{ errors }"
              name="name"
              rules="required"
            >
              <b-form-group label="Name">
                <b-form-input
                  v-model="form.name"
                  placeholder="Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Model -->
            <validation-provider
              v-slot="{ errors }"
              name="model"
              rules="required"
            >
              <b-form-group label="Select Model">
                <v-select
                  v-model="form.model"
                  :options="MODELS"
                  :reduce="m => m.value"
                  placeholder="Select a model"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Data -->
            <!-- <validation-provider
              v-slot="{ errors }"
              name="data"
            >
              <b-form-group>
                <template #label>
                  <span>Data</span>
                  <feather-icon
                    v-b-tooltip.hover="'While performing single run, enter the data to replicate passed down data from the parent node'"
                    icon="InfoIcon"
                    :style="'margin-left: 4px; margin-bottom: 8px'"
                  />
                </template>
                <b-form-input
                  v-model="form.data"
                  placeholder="Data"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider> -->

            <b-row>
              <b-col md="6">
                <!-- Max Tokens -->
                <validation-provider
                  v-slot="{ errors }"
                  name="max tokens"
                  rules="required"
                >
                  <b-form-group label="Max Tokens">
                    <b-form-input
                      v-model="form.max_tokens"
                      placeholder="Max Tokens"
                      type="number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="6">
                <!-- Force JSON -->
                <b-form-group>
                  <template #label>
                    <span>Force JSON</span>
                    <feather-icon
                      v-b-tooltip.hover="'Force the output to be in JSON format'"
                      :style="'margin-left: 4px; margin-bottom: 8px'"
                      icon="InfoIcon"
                    />
                  </template>
                  <b-form-checkbox
                    v-model="form.force_json"
                    switch
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <!-- Prompt -->
        <validation-provider
          v-slot="{ errors }"
          name="prompt"
          rules="required"
        >
          <b-form-group label="Prompt">
            <b-form-textarea
              v-model="form.prompt"
              placeholder="Write your prompt here ..."
              rows="10"
              @change="changeVariables"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- Variables -->
        <div v-if="form.params.length">
          <h5 class="mt-2">
            Variables:
          </h5>

          <b-row class="variables">
            <b-col
              v-for="param of form.params"
              :key="param.name"
              md="6"
              class="border-bottom py-1"
            >
              <b-row class="align-items-center">
                <b-col
                  class="text-capitalize border-0"
                  md="6"
                >
                  {{ param.name }}
                </b-col>
                <b-col md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="param.name"
                    rules="required"
                  >
                    <b-form-input
                      v-model="param.value"
                      :placeholder="`Enter the ${param.name}`"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </validation-observer>
  </b-modal>
</template>

<script setup>
import useJwt from '@/auth/jwt/useJwt'
import {
  BRow, BCol, BModal, BFormGroup, BFormInput, BFormTextarea, BOverlay, BFormCheckbox,
  VBTooltip,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { getCurrentInstance, ref } from 'vue'

import { MODELS, NODE_TYPES } from '../nodeConstants'

const vBTooltip = VBTooltip
const props = defineProps({
  properties: {
    type: Object,
    default: () => {},
  },
  node: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits('node-properties', 'refetch')

const root = getCurrentInstance().proxy
const gptNodeForm = ref()
const isProcessing = ref(false)
const form = ref({
  params: [],
  max_tokens: 1028,
  force_json: false,
})
let variables = []

// Methods
const populateForm = () => {
  if (Object.keys(props.properties).length) {
    const { model, ...properties } = props.properties
    let modelName = ''

    if (model) {
      modelName = typeof model === 'string' ? model : model[0].canonicalName ?? null
    }

    form.value = {
      params: [],
      ...properties,
      model: modelName,
    }

    if (props.node.uuid) form.value.name = props.node.name
  }
}

const clearForm = () => {
  form.value = {
    params: [],
    max_tokens: 1024,
    force_json: false,
  }
  gptNodeForm.value.reset()
}

const changeVariables = () => {
  if (form.value.prompt) {
    const regex = /\{\{([^}]+)\}\}/g
    let inputVariable

    variables = []
    // eslint-disable-next-line no-cond-assign
    while ((inputVariable = regex.exec(form.value.prompt)) !== null) {
      const variable = inputVariable[1]
      if (variable !== 'data') {
        variables.push(variable)
        if (!form.value.params.find(param => param.name === variable)) {
          variables.push(variable)
          form.value.params.push({
            name: variable,
            value: '',
          })
        }
      }
    }

    const formVariables = new Set(form.value.params.map(param => param.name))
    const inputVariables = new Set(variables)

    const removedVariables = formVariables.difference(inputVariables)
    removedVariables.forEach(variable => {
      const index = form.value.params.findIndex(param => param.name === variable)
      form.value.params.splice(index, 1)
    })
  }
}

const handleOk = bvModal => {
  bvModal.preventDefault()
  gptNodeForm.value.validate().then(success => {
    if (success) {
      isProcessing.value = true

      const { data, ...formData } = form.value
      if (data) formData.data = data
      emit('node-properties', formData)

      if (formData.uuid) {
        const payload = {
          ...props.node,
          ...formData,
          node_type: NODE_TYPES.gpt,
        }

        useJwt.updateNode(payload).then(response => {
          root.showSuccessMessage(response)
        }).finally(() => {
          root.$bvModal.hide('gpt-node-modal')
          // emit('refetch')
          isProcessing.value = false
        })
      } else {
        root.$bvModal.hide('gpt-node-modal')
        isProcessing.value = false
      }
    }
  })
}
</script>

<style scoped>
.variables {
  height: 120px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
