<template>
  <div>
    <section
      class="chat-app-window"
      style="position: relative"
    >
      <div class="active-chat">
        <div class="chat-navbar">
          <header class="chat-header">
            <div
              class="d-flex justify-content-between w-100 align-items-center"
            >
              <div class="d-flex align-items-center">
                <div class="sidebar-toggle d-block d-lg-none mr-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21px"
                    height="21px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="cursor-pointer feather feather-menu"
                  >
                    <line
                      x1="3"
                      y1="12"
                      x2="21"
                      y2="12"
                    />
                    <line
                      x1="3"
                      y1="6"
                      x2="21"
                      y2="6"
                    />
                    <line
                      x1="3"
                      y1="18"
                      x2="21"
                      y2="18"
                    />
                  </svg>
                </div>
                <b-avatar
                  :src="avatar.botImg"
                  class="mr-1 avatar-border-2 box-shadow-1"
                  size="36"
                  variant="transparent"
                />
                <!-- <span
                  class="b-avatar mr-1 cursor-pointer badge-minimal badge-secondary rounded-circle"
                  style="width: 36px; height: 36px"
                ><span class="b-avatar-img"><img
                  src="/img/new-logo.5e5f5955.svg"
                  alt="avatar"
                ></span><span
                  class="b-avatar-badge badge-success"
                  style="
                      font-size: calc(10.08px);
                      bottom: 0px;
                      right: 0px;
                    "
                /></span> -->
                <h6 class="mb-0 text-white">
                  ChatBot
                </h6>
              </div>

              <!-- Execution Mode -->
              <Transition name="slide">
                <b-card
                  v-if="currentExecutionMode && currentExecutionNode"
                  class="execution-mode pr-1"
                >
                  <b-button
                    v-b-tooltip.hover="`Remove Mode`"
                    class="close-icon small-btn"
                    variant="danger"
                    @click="removeExecutionMode"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>

                  <p class="font-weight-bold text-capitalize mb-0 text-primary">
                    Mode: {{ currentExecutionMode.mode }} Mode
                  </p>
                  <p class="font-weight-bold mb-0 text-primary">
                    Node: {{ currentExecutionNode.data.template }}
                  </p>
                </b-card>
              </Transition>
            </div>
          </header>
        </div>
        <vue-perfect-scrollbar
          ref="refChatLogPS"
          :settings="perfectScrollbarSettings"
          class="ai-chats scroll-area user-chats"
        >
          <!-- Chat -->
          <div class="chats">
            <div
              v-for="msg,index of messages"
              :key="index"
              class="chat d-flex p-1"
              :class="msg.from === 'chatbot' && 'chat-left'"
            >
              <div class="chat-avatar">
                <b-avatar
                  variant="primary"
                  :src="msg.from === 'chatbot' ? avatar.botImg : avatar.avatar"
                />
              </div>
              <div class="chat-body ml-1">
                <div class="chat-content">
                  <p>{{ msg.message }}</p>
                </div>
              </div>
            </div>
            <div
              v-if="isBotTyping"
              class="bot-typing"
            >
              <Preloader class="pl-2" />
            </div>
          </div>
        </vue-perfect-scrollbar>

        <form class="chat-app-form">
          <div class="d-flex w-100 p-2">
            <div
              role="group"
              class="input-group input-group-merge form-send-message mr-1"
            >
              <span
                class="w-100"
              >
                <textarea
                  v-model="typedMessage"
                  rows="1"
                  max-rows="8"
                  tag="pre"
                  :placeholder="'Type you message'"
                  class="form-control"
                  style="resize: none"
                  :disabled="jsonInputEnabled"
                  @keyup.enter="onEnter"
                /></span>
            </div>
            <button
              type="button"
              class="btn btn-primary"
              :disabled="isBotTyping || !typedMessage"
              @click="onSendMessage"
            >
              Send
            </button>
          </div>

          <div class="d-flex align-items-baseline">
            <b-form-checkbox
              v-model="jsonInputEnabled"
              class="ml-2"
              switch
              @change="onJsonInputToggle"
            >
              JSON mode
            </b-form-checkbox>

            <b-button
              v-if="jsonInputEnabled"
              variant="outline-primary"
              class="ml-1"
              size="sm"
              @click="$bvModal.show('json-input-modal')"
            >
              <feather-icon icon="Edit2Icon" />
            </b-button>
          </div>
        </form>
      </div>
    </section>

    <json-input-modal
      @message="onJsonInputMessage"
    />
  </div>
</template>
<script setup>
import {
  BAvatar, BCard, BButton, BFormCheckbox, VBTooltip,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Preloader from '@/views/common/components/Preloader.vue'
import {
  computed, getCurrentInstance, nextTick, onMounted, ref, watch,
} from 'vue'
import useChatIde from './useChatIde'
import JsonInputModal from './JsonInputModal.vue'

const vBTooltip = VBTooltip

const perfectScrollbarSettings = {
  maxScrollbarLength: 150,
}
const {
  messages, sendMessage, clearChat, typedMessage,
} = useChatIde()

const props = defineProps({
  roomId: {
    type: String,
    required: true,
  },
})

const emits = defineEmits(['hide-chat'])

const root = getCurrentInstance().proxy.$root

const jsonInputEnabled = ref(false)
const jsonInputMessage = ref({})

const isBotTyping = computed(() => root.$store.state.dataFlow.isBotTyping)
const currentModule = computed(() => root.$store.state.dataFlow.currentModule)
const currentExecutionMode = computed(() => root.$store.state.dataFlow.currentExecutionData)
const currentExecutionNode = computed(() => {
  if (!currentExecutionMode.value?.node) return null

  return Object.values(root.$store.state.dataFlow.drawflowData?.drawflow?.[currentModule.value]?.data).find(n => n.id === currentExecutionMode.value?.node)
})

const userInfo = {
  firstname: 'suman',
  lastname: 'thapa',
}
const avatar = {
  fullName: userInfo.firstname + userInfo.lastname,
  avatar: userInfo.avatar,
  botImg: '/img/13-small.d796bffd.png',
}

// Methods
const onSendMessage = () => {
  sendMessage(
    props.roomId,
    currentExecutionMode.value?.mode,
    currentExecutionMode.value?.node,
    Object.keys(jsonInputMessage.value).length ? jsonInputMessage.value : null,
  )
  nextTick(() => {
    jsonInputMessage.value = {}
  })
}

const onEnter = event => {
  if (event.shiftKey) {
    // Insert a new line
    typedMessage.value += '\n'
  } else onSendMessage()
}

const onJsonInputToggle = value => {
  if (value) root.$bvModal.show('json-input-modal')
  else {
    typedMessage.value = ''
    jsonInputMessage.value = {}
  }
}

const onJsonInputMessage = value => {
  typedMessage.value = JSON.stringify(value)
  jsonInputMessage.value = value
}

const removeExecutionMode = () => {
  root.$store.dispatch('dataFlow/setCurrentExecutionData', {})
}

watch(currentModule, () => {
  clearChat()
  emits('hide-chat')
})

onMounted(() => {
  root.$store.dispatch('dataFlow/setCurrentGatewayInInteractiveActions')
})
</script>
<style lang="scss">
.ai-chats {
  height: 42vh;
}
.bottom-0 {
  bottom: 0;
}
.bot-typing {
  position: fixed;
  bottom: 12vh;
}
.preloader {
  margin: 0 0 48px 24px;
}
.execution-mode {
  position: absolute;
  top: 0;
  right: 0;
  .close-icon {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(50%) !important;
  opacity: 0 !important;
}

</style>
