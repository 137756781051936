<template>
  <b-modal
    id="http-node-modal"
    :title="`${form.uuid ? 'Update' : 'Add'} Http Node Properties`"
    size="xl"
    :ok-title="form.uuid ? 'Update' : 'Add'"
    static
    no-close-on-backdrop
    @ok="handleOk"
    @shown="populateForm"
    @hidden="resetForm"
  >
    <validation-observer ref="httpNodeForm">
      <b-overlay :show="isProcessing">
        <b-row>
          <b-col md="6">
            <!-- Description -->
            <validation-provider
              v-slot="{ errors }"
              name="description"
              rules="required"
            >
              <b-form-group label="Description">
                <b-form-textarea
                  v-model="form.description"
                  placeholder="Explain what this node does"
                  :rows="form.uuid ? 8 : 4"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <!-- Name -->
            <validation-provider
              v-if="form.uuid"
              v-slot="{ errors }"
              name="name"
              rules="required"
            >
              <b-form-group label="Name">
                <b-form-input
                  v-model="form.name"
                  placeholder="Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Url -->
            <validation-provider
              v-slot="{ errors }"
              name="url"
              rules="required"
            >
              <b-form-group label="Url">
                <b-form-input
                  v-model="form.url"
                  placeholder="http://www.example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- HTTP Method -->
            <validation-provider
              v-slot="{ errors }"
              name="http method"
              rules="required"
            >
              <b-form-group label="HTTP Method">
                <v-select
                  v-model="form.method"
                  :options="methodOptions"
                  placeholder="Select an Http Method"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Data -->
            <!-- <validation-provider
              v-slot="{ errors }"
              name="data"
            >
              <b-form-group>
                <template #label>
                  <span>Data</span>
                  <feather-icon
                    v-b-tooltip.hover="'While performing single run, enter the data to replicate passed down data from the parent node'"
                    icon="InfoIcon"
                    :style="'margin-left: 4px'"
                  />
                </template>
                <b-form-input
                  v-model="form.data"
                  placeholder="Data"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider> -->
          </b-col>

        </b-row>
        <!-- Headers -->
        <div>
          <div class="d-flex align-items-baseline">
            <h5 class="mt-2">
              Headers:
            </h5>

            <b-button
              variant="link"
              size="sm"
              @click="form.headers.push({ name: '', value: '' })"
            >
              <feather-icon icon="PlusIcon" />
              Add Headers
            </b-button>
          </div>
          <b-row>
            <b-col
              v-for="header, index of form.headers"
              :key="index"
              md="6"
              class="border-bottom py-1"
            >
              <b-row class="align-items-center">
                <b-col md="5">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="'name'"
                    rules="required"
                  >
                    <b-form-input
                      v-model="header.name"
                      placeholder="Header name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col md="5">
                  <validation-provider
                    v-slot="{ errors }"
                    name="value"
                    rules="required"
                  >
                    <b-form-input
                      v-model="header.value"
                      placeholder="Header value"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col md="2">
                  <feather-icon
                    class="text-danger cursor-pointer"
                    icon="Trash2Icon"
                    @click="removeItem(index, 'headers')"
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>

        <!-- Params -->
        <div>
          <div class="d-flex align-items-baseline">
            <h5 class="mt-2">
              Params:
            </h5>

            <b-button
              variant="link"
              size="sm"
              @click="form.params.push({ name: '', value: '' })"
            >
              <feather-icon icon="PlusIcon" />
              Add Params
            </b-button>
          </div>
          <b-row>
            <b-col
              v-for="param, index of form.params"
              :key="index"
              md="6"
              class="border-bottom py-1"
            >
              <b-row class="align-items-center">
                <b-col md="5">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="'name'"
                    rules="required"
                  >
                    <b-form-input
                      v-model="param.name"
                      placeholder="Param name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col md="5">
                  <validation-provider
                    v-slot="{ errors }"
                    name="value"
                    rules="required"
                  >
                    <b-form-input
                      v-model="param.value"
                      placeholder="Param value"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col md="2">
                  <feather-icon
                    class="text-danger cursor-pointer"
                    icon="Trash2Icon"
                    @click="removeItem(index, 'params')"
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </validation-observer>
  </b-modal>
</template>

<script setup>
import {
  BRow, BCol, BModal, BFormGroup, BFormInput, BFormTextarea, BOverlay, BButton,
  // VBTooltip,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import vSelect from 'vue-select'
import { getCurrentInstance, ref } from 'vue'
import useJwt from '@/auth/jwt/useJwt'
import { NODE_TYPES } from '../nodeConstants'

extend('url', value => {
  const regex = /^(https?:\/\/)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(\/\S*)?$/
  if (regex.test(value)) return true
  return 'Please enter a valid url'
})

// const vBTooltip = VBTooltip
const props = defineProps({
  properties: {
    type: Object,
    default: () => {},
  },
  node: {
    type: Object,
    required: true,
  },
})

const root = getCurrentInstance().proxy
const emit = defineEmits('node-properties', 'refetch')
const isProcessing = ref(false)
const httpNodeForm = ref()
const methodOptions = ['GET', 'POST', 'PATCH', 'PUT', 'DELETE']
const form = ref({
  params: [],
  headers: [],
})

// Methods
const populateForm = () => {
  if (Object.keys(props.properties).length) {
    form.value = props.properties
  }
  if (props.node.uuid) form.value.name = props.node.name
}

const resetForm = () => {
  form.value = {
    params: [],
    headers: [],
  }
  httpNodeForm.value.reset()
}

const removeItem = (index, field) => {
  form.value[field].splice(index, 1)
}

const handleOk = bvModal => {
  bvModal.preventDefault()
  httpNodeForm.value.validate().then(success => {
    if (success) {
      isProcessing.value = true
      const { data, ...formData } = form.value
      if (data) formData.data = data

      emit('node-properties', formData)
      const payload = {
        ...props.node,
        ...formData,
        node_type: NODE_TYPES.http,
      }

      if (form.value.uuid) {
        useJwt.updateNode(payload).then(response => {
          root.showSuccessMessage(response)
        }).finally(() => {
          root.$bvModal.hide('http-node-modal')
          // emit('refetch')
          isProcessing.value = false
        })
      } else {
        root.$bvModal.hide('http-node-modal')
        isProcessing.value = false
      }
    }
  })
}
</script>
