<template>
  <b-modal
    id="shareNodeModal"
    title="Share Node"
    size="lg"
    no-close-on-backdrop
    ok-title="Confirm"
    static
    @shown="onModalShown"
    @ok="handleOk"
    @hidden="$refs.shareNodeForm.reset()"
  >
    <validation-observer ref="shareNodeForm">
      <validation-provider
        v-slot="{ errors }"
        name="share with"
      >
        <b-form-group label="Share With">
          <v-select
            v-model="form.shares"
            :options="nodes"
            :reduce="node => node.value"
            placeholder="Select a node"
          >
            <template #option="{label, desc}">
              <h4 class="mb-0">
                {{ label }}
              </h4>
              <p>{{ desc }}</p>
            </template>
          </v-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>
    </validation-observer>

    <!-- <hr> -->

    <div v-if="currentNode && currentNode.shares?.length">
      <h6>Shared With:</h6>
      <b-list-group>
        <b-list-group-item
          v-for="sharedNode in currentNode.shares"
          :key="sharedNode.uuid"
          @mouseenter="hoveredNode = sharedNode.uuid"
          @mouseleave="hoveredNode = null"
        >
          <div class="d-flex justify-content-between">
            <span>{{ sharedNode.name }}</span>
            <b-badge
              v-if="hoveredNode === sharedNode.uuid"
              variant="danger"
              class="cursor-pointer"
              @click="onRevokeShare(sharedNode.uuid)"
            >
              Revoke Share
            </b-badge>
          </div>
        </b-list-group-item>
      </b-list-group>
    </div>

    <!-- Share Confirm Modal -->
    <confirm-modal
      v-if="currentNode"
      modal="share-confirm-modal"
      title="Share Node?"
      :description="`Sharing the node will result in ${currentNode.shares?.length ? 'replacement of your current share node' : 'deletion of your current handler'}, are you sure you want to share this node?`"
      ok-title="Share"
      ok-variant="primary"
      @confirm="shareNode"
    />

    <!-- Revoke Share Confirm Modal -->
    <confirm-modal
      modal="revoke-share-confirm-modal"
      title="Revoke Node Share?"
      description="Are you sure you want to revoke sharing with this node?"
      ok-title="Revoke"
      ok-variant="danger"
      @confirm="revokeShare"
    />
  </b-modal>
</template>
<script>
import {
  BModal, BFormGroup, BListGroup, BListGroupItem, BBadge,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import useJwt from '@/auth/jwt/useJwt'
import vSelect from 'vue-select'
import ConfirmModal from '@/views/Modals/ConfirmModal.vue'
import useDrawflow from '../useDrawflow'

const { isUUID } = useDrawflow

export default {
  components: {
    BModal,
    BFormGroup,
    BListGroup,
    BListGroupItem,
    BBadge,
    ConfirmModal,
    vSelect,
    ValidationObserver,
    ValidationProvider,
  },

  props: {
    node: {
      type: String,
      required: true,
    },
    properties: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      nodes: [],
      form: {},
      hoveredNode: null,
      nodeToRevokeShare: null,
    }
  },
  computed: {
    currentGateway() {
      return this.$store.state.dataFlow.currentGateway
    },
    currentNode() {
      return this.$store.state.dataFlow.drawflowData.drawflow[this.currentGateway]?.data[this.node]
    },
  },
  methods: {
    handleOk(bvModal) {
      bvModal.preventDefault()
      if (isUUID(this.node)) {
        this.$bvModal.show('share-confirm-modal')
      } else this.shareNode()
    },
    populateForm() {
      if (this.properties && Object.keys(this.properties).length) {
        const [shares] = this.properties.shares
        this.form.shares = JSON.parse(JSON.stringify(shares))
      }
    },
    getNodesForShare() {
      const params = isUUID(this.node) ? { uuid: this.node } : {}

      useJwt.getSharedNodes({ params })
        .then(response => {
          this.nodes = response.data.map(node => ({
            value: node.uuid,
            label: node.name,
            desc: node.description,
          }))
        })
    },
    onModalShown() {
      this.populateForm()
      this.getNodesForShare()
    },
    shareNode() {
      this.$refs.shareNodeForm.validate().then(success => {
        if (success) {
          if (isUUID(this.node)) {
            const params = {
              share_with: this.form.shares,
              workflow: this.node,
            }
            useJwt.shareNode(params).then(response => {
              this.showSuccessMessage(response)
              this.$refs.shareNodeForm.reset()
              this.$emit('refetch')

              this.$bvModal.hide('shareNodeModal')
            }).catch(error => {
              this.showErrorMessage(error)
            })
          } else {
            this.$emit('set-handler', this.form)
            this.$bvModal.hide('shareNodeModal')
          }
        }
      })
    },
    onRevokeShare(shareUid) {
      this.nodeToRevokeShare = shareUid
      this.$bvModal.show('revoke-share-confirm-modal')
    },
    revokeShare() {
      const params = {
        workflow: this.node,
        shared_with: this.nodeToRevokeShare,
      }

      useJwt.revokeNodeSharing(params).then(response => {
        this.showSuccessMessage(response)
        // this.$emit('refetch')

        this.$bvModal.hide('shareNodeModal')
      }).catch(error => {
        this.showErrorMessage(error)
      })
    },
  },
}
</script>

<style>
#shareNodeModal .list-group-item:active {
  background-color: transparent !important;
}
</style>
