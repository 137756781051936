<template>
  <b-modal
    id="node-description-modal"
    :title="`${form.uuid ? 'Update' : 'Add'} ${props.node.node_type.split('-').join(' ')} properties`"
    :ok-title="`${form.uuid ? 'Update' : 'Add'}`"
    size="lg"
    static
    no-close-on-backdrop
    @ok="handleOk"
    @shown="populateForm"
    @hidden="() => { form = {}; nodeDescriptionForm.reset() }"
  >
    <validation-observer ref="nodeDescriptionForm">
      <b-overlay :show="isProcessing">
        <!-- Name -->
        <validation-provider
          v-if="form.uuid"
          v-slot="{ errors }"
          name="name"
          :rules="`required|gateway:${node.node_type}`"
        >
          <b-form-group label="Name">
            <b-form-input
              v-model="form.name"
              placeholder="Name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- Description -->
        <validation-provider
          v-slot="{ errors }"
          name="description"
          rules="required"
        >
          <b-form-group label="Description">
            <b-form-textarea
              v-model="form.description"
              placeholder="Explain what this node does"
              rows="5"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-overlay>
    </validation-observer>
  </b-modal>
</template>

<script setup>
import {
  BModal, BFormGroup, BFormInput, BFormTextarea, BOverlay,
} from 'bootstrap-vue'
import { ref, getCurrentInstance } from 'vue'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import useJwt from '@/auth/jwt/useJwt'
import { NODE_TYPES } from '../nodeConstants'

extend('gateway', (value, [type]) => {
  if (type !== 'gateway') return true
  const regex = /^(?!\/)(.*\/.*)(?<!\/)$/
  if (regex.test(value)) return true
  return 'Gateway name should contain \'/\', but not at the start or at the end'
})

const props = defineProps({
  properties: {
    type: Object,
    default: () => {},
  },
  node: {
    type: Object,
    required: true,
  },
})

const root = getCurrentInstance().proxy
const emit = defineEmits('node-properties')

const isProcessing = ref(false)
const form = ref({})
const nodeDescriptionForm = ref()

// Methods
const populateForm = () => {
  if (Object.keys(props.properties).length) {
    form.value = props.properties
  }
  if (props.node.uuid) form.value.name = props.node.name
}

const handleOk = bvModal => {
  bvModal.preventDefault()
  nodeDescriptionForm.value.validate().then(success => {
    if (success) {
      isProcessing.value = true
      const { data, ...formData } = form.value
      if (data) formData.data = data
      emit('node-properties', formData)

      if (formData.uuid) {
        const params = {
          ...props.node,
          ...formData,
        }

        let api = 'updateGateway'
        if (props.node.node_type !== 'gateway') {
          api = 'updateNode'
          const [nodeType] = props.node.node_type.split('-')
          params.node_type = NODE_TYPES[nodeType]
        }

        useJwt[api](params).then(response => {
          root.showSuccessMessage(response)
          // emit('refetch')
          // emit('update-name', form.value.name, form.value.uuid)
          root.$bvModal.hide('node-description-modal')
        }).catch(error => {
          root.showErrorMessage(error)
        }).finally(() => {
          isProcessing.value = false
        })
      } else {
        isProcessing.value = false
        root.$bvModal.hide('node-description-modal')
      }
    }
  })
}

</script>
