<template>
  <b-modal
    id="shared-workflow-node-modal"
    title="Select Shared Workflow Node"
    :ok-title="`${form.uuid ? 'Update' : 'Add'}`"
    size="lg"
    static
    no-close-on-backdrop
    :ok-disabled="isProcessing"
    :cancel-disabled="isProcessing"
    @ok="handleOk"
    @shown="onShown"
    @hidden="resetForm"
  >
    <b-overlay :show="isProcessing">
      <validation-observer ref="sharedNodeForm">
        <!-- Name -->
        <validation-provider
          v-if="form.uuid"
          v-slot="{ errors }"
          name="name"
          rules="required"
        >
          <b-form-group label="Name">
            <b-form-input
              v-model="form.name"
              placeholder="Name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- Shared Workflow -->
        <validation-provider
          v-slot="{ errors }"
          name="shared workflow"
          rules="required"
        >
          <b-form-group label="Shared Workflow">
            <v-select
              v-model="form.shares"
              :reduce="node => node.uuid"
              placeholder="Select a shared workflow"
              label="name"
              :options="sharedWorkflows"
              @input="setDescription"
            >
              <template #option="{name, description}">
                <h4 class="mb-0">
                  {{ name }}
                </h4>
                <p>{{ description }}</p>
              </template>
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>

<script setup>
import { getCurrentInstance, ref } from 'vue'
import {
  BModal, BFormGroup, BOverlay, BFormInput,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import useDrawflow from '../useDrawflow'
import { NODE_TYPES } from '../nodeConstants'

const { isUUID } = useDrawflow
const emit = defineEmits('node-properties', 'refetch')
const root = getCurrentInstance().proxy

const sharedNodeForm = ref()
const form = ref({
  node_type: NODE_TYPES.workflow,
})
const sharedWorkflows = ref([])
const isProcessing = ref(false)

const props = defineProps({
  properties: {
    type: Object,
    default: () => {},
  },
  node: {
    type: Object,
    required: true,
  },
})

const populateForm = () => {
  if (Object.keys(props.properties).length) {
    form.value = JSON.parse(JSON.stringify(props.properties))

    const { shares } = props.properties
    form.value.shares = Array.isArray(shares) ? shares[0] : shares
  }
  if (props.node.uuid) form.value.name = props.node.name
}

const getSharedNodes = () => {
  isProcessing.value = true
  const params = { uuid: isUUID(props.node.uuid) ? props.node.uuid : null }
  useJwt.getSharedNodes({ params }).then(response => {
    sharedWorkflows.value = response.data
  }).finally(() => {
    isProcessing.value = false
  })
}

const onShown = () => {
  populateForm()
  getSharedNodes()
}

const setDescription = uuid => {
  form.value.description = sharedWorkflows.value.find(workflow => workflow.uuid === uuid)?.description
}

const resetForm = () => {
  form.value = {
    node_type: NODE_TYPES.workflow,
  }
  sharedNodeForm.value.reset()
}

const handleOk = bvModal => {
  bvModal.preventDefault()
  sharedNodeForm.value.validate().then(success => {
    if (success) {
      isProcessing.value = true
      const { data, ...formData } = form.value
      if (data) formData.data = data

      emit('node-properties', formData, 'shares')

      const params = {
        name: props.node.name,
        ...formData,
        node_type: NODE_TYPES.workflow,
      }

      if (formData.uuid) {
        useJwt.shareNode({
          name: params.name,
          workflow: params.uuid,
          share_with: params.shares,
        }).then(response => {
          root.showSuccessMessage(response)
          root.$bvModal.hide('shared-workflow-node-modal')
          // emit('refetch')
        }).finally(() => {
          isProcessing.value = false
        })
      } else {
        root.$bvModal.hide('shared-workflow-node-modal')
        isProcessing.value = false
      }
    }
  })
}
</script>
